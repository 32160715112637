<template>
  <v-app>
      <Babero/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Babero from '@/components/Babero.vue'

export default {
  name: 'BaberoView',
  components: {
    Babero
  }
}
</script>

